(function (window, $, undefined) {
  const MOBILE_REGEX = /Android|iPhone|iPad|iPod/i;

  class Overlay {

    backdrop = null;
    body = $(document.body);
    element = null;
    onClose = () => {};
    onOpen = () => {};
    options = null;
    touchStart = {};

    constructor(html, options) {
      this.onTouchMove = this.onTouchMove.bind(this);
      this.onTouchStart = this.onTouchStart.bind(this);

      this.options = Object.assign(this.defaultOptions, options);
      this.element = this.createElement(html);
      this.backdrop = this.createBackdrop();

      this.backdrop.append(this.element);

      if (this.options.classList) {
        this.element.addClass(this.options.classList);
      }

      this.body.append(this.backdrop);
    }

    get defaultOptions() {
      return {
        classList: '',
        clickOutsideToClose: true
      };
    }

    close() {
      requestAnimationFrame(() => {
        this.backdrop.removeClass('backdrop-visible');
        this.backdrop.addClass('backdrop-hidden');
      });

      this.restoreBodyScroll();

      if ('onanimationend' in window) {
        this.backdrop.one('animationend', () => this.onClose());
      } else {
        this.onClose();
      }
    }

    createBackdrop() {
      return $('<div class="backdrop overlay-backdrop">');
    }

    createElement(html) {
      const element = $('<div class="overlay"></div>');

      return element.append(html).on('click', (event) => {
        event.stopPropagation();
      });
    }

    disableBodyScroll() {
      if (isMobile()) {
        this.body.on('touchmove', (event) => event.preventDefault());
        this.element[0].addEventListener('touchstart', this.onTouchStart);
        this.element[0].addEventListener('touchmove', this.onTouchMove);
      } else {
        this.body.on('wheel', (event) => event.preventDefault());
        this.element[0].addEventListener('wheel', this.onScroll);
      }
    }

    open() {
      if (this.backdrop.parent().length === 0) {
        this.body.append(this.backdrop);
      }

      if (this.options.clickOutsideToClose) {
        this.backdrop.one('click', this.close.bind(this));
      }

      requestAnimationFrame(() => {
        this.backdrop.removeClass('backdrop-hidden');
        this.backdrop.addClass('backdrop-visible');
      });

      this.disableBodyScroll();
      this.onOpen();
    }

    onScroll = (event) => {
      event.stopPropagation();

      const {deltaY} = event;
      const scrollTop = Math.ceil(this.element.prop('scrollTop'));

      if (deltaY < 0 && scrollTop === 0) {
        return Boolean(event.preventDefault());
      }
      
      const clientHeight = this.element.prop('clientHeight');
      const scrollHeight = this.element.prop('scrollHeight');
      const scrollBottom = scrollHeight - clientHeight - scrollTop;

      // scrollBottom might be less than 0 due to rounding
      if (deltaY > 0 && scrollBottom <= 0) {
        return Boolean(event.preventDefault());
      }
    }

    onTouchMove(event) {
      const {touches: [touch]} = event;

      event.deltaX = this.touchStart.pageX - touch.pageX;
      event.deltaY = this.touchStart.pageY - touch.pageY;

      // update touch start
      this.onTouchStart(event);

      return this.onScroll(event);
    }

    onTouchStart({touches: [touch]}) {
      this.touchStart.pageX = touch.pageX;
      this.touchStart.pageY = touch.pageY;
    }

    remove() {
      this.backdrop.remove();
    }

    restoreBodyScroll() {
      if (isMobile()) {
        this.body.off('touchmove');
        this.element[0].removeEventListener('touchstart', this.onTouchStart);
        this.element[0].removeEventListener('touchmove', this.onTouchMove);
      } else {
        this.body.off('wheel');
        this.element[0].removeEventListener('wheel', this.onScroll);
      }
    }
  }

  class Lightbox extends Overlay {

    constructor(html, options) {
      super(html, options);

      // the max width of the lightbox is 80%
      this.element.find('.media-content').css({
        paddingTop: `${100 / this.options.aspectRatio * 0.8}%`
      });
    }

    get defaultOptions() {
      return Object.assign(super.defaultOptions, {
        aspectRatio: 16 / 9
      });
    }

    createBackdrop() {
      return $('<div class="backdrop lightbox-backdrop">');
    }

    createElement(html) {
      const element = $(`
        <div class="lightbox">
          <div class="lightbox-cell">
            <div class="overlay-close-button" aria-label="Close"></div>
            <div class="media-content">
            </div>
          </div>
        </div>
      `);

      element.find('.media-content').append(html);

      return element;
    }

    disableBodyScroll() {
      // iframes capture mouse events
    }

    restoreBodyScroll() {
      // iframes capture mouse events
    }
  }

  window.Overlay = Overlay;
  window.Lightbox = Lightbox;

  function isMobile() {
    return MOBILE_REGEX.test(navigator.userAgent);
  }

})(window, jQuery);
